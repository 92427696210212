import React from "react";
import {Buffer} from 'buffer';
import {WebIO, Texture, Document} from "@gltf-transform/core";
import { styled } from '@mui/material/styles';
import {Box, Button, CircularProgress, ThemeProvider, Typography, useTheme} from "@mui/material";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';


import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


import { createTheme } from "@mui/material/styles";
import {blue, blueGrey, grey} from "@mui/material/colors";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#444444",
        },
        secondary: {
            main: "#FFFFFF",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "*": {
                    margin: 0,
                    padding: 0,
                },
                ul: {
                    listStyle: "none",
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: { verticalAlign: "middle" },
            },
        },
    },
});


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

async function uploadBlobToIpfs(blob, fileName) {
    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Basic MkVQNVNFeEFhRUJ2UDVxbXJHOEJRMWtaZFBaOjAzOTkwODRhMzc0MjVkOWRiNzlmZTVmMDEwNmNiNTY5"
    );

    const formdata = new FormData();
    formdata.append("", blob, fileName);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    return await fetch("https://ipfs.infura.io:5001/api/v0/add", requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
}

async function replaceTexture(glbPath, base64Texture) {
    console.log("REPLACE TEXTURE");

    const io = new WebIO({credentials: 'include'});

    const document = await io.read(glbPath);

    // Decode the base64 string to a Buffer
    const imageBuffer = Buffer.from(base64Texture, 'base64');

    // Create a new texture
    const newTexture = new Texture(document.getGraph())
        .setImage(imageBuffer)
        .setName('newTexture')
        .setMimeType('image/png'); // Adjust MIME type if necessary

    // Replace the texture in the first material (as an example)
    document.getRoot().listMaterials()[0].setBaseColorTexture(newTexture);

    console.log(document.getRoot().listMaterials())

    // Write the modified GLB file
    const glb = await io.writeBinary(document);
    console.log("GLB")
    // console.log(glb)
    // save to disk
    const blob = new Blob([glb], {type: 'model/gltf-binary'});
    let url = URL.createObjectURL(blob);
    let loader = new GLTFLoader();

    const threeLoadResult = await loader.loadAsync(url);

    const exporter = new GLTFExporter();

    // Define your options here
    const options = {
        binary: true, // Required for GLB format
        // ... other options
    };

    const parsedArray =  await exporter.parseAsync(threeLoadResult.scene, options);

    const threeBlob = new Blob([parsedArray], {type: 'model/gltf-binary'});

    const result = await uploadBlobToIpfs(threeBlob, "new.glb");
    const glbUrl = "https://mml.infura-ipfs.io/ipfs/" + result.Hash;

    const mmlString = "<m-character src=\"" + glbUrl + "\" ></m-character>"

    console.log("MML STRING: ", mmlString)
    // turn to blob
    const mmlBlob = new Blob([mmlString], {type: 'text/plain'});
    const mmlResult = await uploadBlobToIpfs(mmlBlob, "new.mml");
    const mmlUrl = "https://mml.infura-ipfs.io/ipfs/" + mmlResult.Hash;

    return mmlUrl;



}

function FullscreenSpinner() {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ height: '100vh' }}  // This ensures it takes the full viewport height
        >
            <CircularProgress />
        </Box>
    );
}

function App() {

    const [mmlUrl, setMmlUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const handleDrop = React.useCallback((files) => {
        setLoading(true);
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');

        if (files && files[0]) {
            const reader = new FileReader();
            console.log("READING FILE")

            reader.onload = function (e) {
                const img = new Image();
                console.log("NEW IMAGE")

                img.onload = async function () {
                    console.log("IMAGE LOAD")
                    // Set canvas size to desired upscale size
                    const scaleFactor = 1; // Example scale factor
                    canvas.width = 512;
                    canvas.height = 512;

                    // Nearest-neighbor scaling
                    ctx.imageSmoothingEnabled = false;
                    ctx.drawImage(img, 0, 0, 512, 512);

                    // get PNG bytes
                    const base64Image = canvas.toDataURL('image/png').replace(/^data:image\/png;base64,/, "");
                    // turn to byte array
                    const imageBuffer = Buffer.from(base64Image, 'base64');

                    const newMmlUrl = await replaceTexture("SK_Blockman2.glb", imageBuffer);
                    setLoading(false)
                    setMmlUrl(newMmlUrl);

                    console.log("SETTT")

                    console.log("https://mml-view.space?url=" + mmlUrl)


                };

                img.src = e.target.result;
            };

            reader.readAsDataURL(files[0]);
        }
    });

    return (
        <>
            <ThemeProvider theme={theme}>

            <Box
                onDragOver={e => {
                    e.preventDefault()
                }
                }
                onDrop={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleDrop(e.dataTransfer.files)
                }} sx={{
                bgcolor: 'black',
                position: "absolute",
                width: "100%",
                height: "100%",
            }}>


                {mmlUrl && !loading &&
                    <>
                        <Box
                            position={"absolute"}
                            bottom={"10px"}
                            left={"10px"}
                        >
                            <Button variant="contained" startIcon={<CopyAllIcon />} onClick={
                            () => {
                                navigator.clipboard.writeText( mmlUrl)
                            }
                            }>Copy Avatar URL</Button>
                            <Typography mt={1} variant="h1" style={{color: "white", fontSize: "12px", fontWeight: "bold",}}>
                                {mmlUrl}
                            </Typography>
                        </Box>
                        <iframe style={{
                            border: "none",
                            width: "100%",
                            height: "100%",
                            pointerEvents: "none"
                        }}
                                src={"https://mml-view.space?bg=sky.glb&rotateSpeed=0.0001&anim=happy.glb&preview=true&backgroundColor=black&url=" + mmlUrl}></iframe>
                    </>
                }}

                {loading && <FullscreenSpinner/>}

                {!mmlUrl && !loading && <Box>
                   {/*// centered prompt to drag minecraft skin*/}
                    <Box style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                        textAlign: "center",
                    }}>
                        <Typography variant="h1" style={{fontSize: "30px", fontWeight: "bold",}}>
                            Drag your Minecraft skin here or
                        </Typography>
                        <Button sx={{
                            mt: 2,
                        }
                        } component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Select skin
                            <VisuallyHiddenInput type="file" accept=".png"  onChange={(e) =>    handleDrop(e.target.files)} onSubmit={(e) => {
                            console.log(e)}
                            }/>
                        </Button>
                        </Box>
                </Box>
                }





            </Box>
               < /ThemeProvider>
        </>
    );
}

export default App;
